import * as Yup from "yup";
import numeral from "numeral";
import moment from "moment";
import {
    ALPHABET_NUMERIC_ONLY,
    ALPHABET_ONLY,
    ALPHABET_WITH_HYPHEN_DOT_REGEX,
    ALPHABET_WITH_NUMBER_HYPHEN_DOT_REGEX, OTHER_VALUE,
    REQUIRED_FIELD
} from "../../../../../constants";

function PersonalDetailValidate(validationObj, keysValidation, isFromClientDetail, minAge, fieldObj9, fieldObj8,
								fieldObj2, strategySlug, client, ageSelected, minVal,
								showClientContribution, fieldObj6, fieldObj7, props, fieldObj11) {
    validationObj.forEach((field: any) => {
        if (field.name === "email") {
            keysValidation[field.name] = Yup.string().trim().email("Please enter a valid email").required(REQUIRED_FIELD);
        } else if (!isFromClientDetail && (field.name === "cellPhone" || field.name === "workPhoneNumber" || field.name === "homePhone")) {
            keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD);
        }
        if (field.isRequired) {
            if (field.name && (field.name === "dob")) {
                keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).test(
                    "Is Dob Valid?",
                    `Participants age must be between ${minAge}-65 - Contact your agent`,
                    (value) => {
                        let age: number = moment().diff(value, "years");
                        return (!((age || age === 0) && (age < minAge || age > 65)));
                    },
                ).nullable();
             } else if (field.name && (field.name === "occupationId" || field.name === "industryId")) {
                // keysValidation[field.name] = field.type && field.type === "number" ? Yup.number().nullable()
                //     .required(REQUIRED_FIELD) : Yup.string().trim();         //TODO NIWC-2807: code commented due to Group users face an issue.
                keysValidation[field.name] = field.type && field.type === "number" ? Yup.number().nullable(true) : Yup.string().trim();
            } else if (field.name === "employedTime") {
                keysValidation[field.name] = Yup.string().trim().test(field.name, REQUIRED_FIELD, function (value) {
                    // employedTime is getting "0" some time
                    return !!(parseInt(this.parent[field.name]))
                }).nullable();
            } else if (field.name === "employeeSSN") {
                keysValidation[field.name] = Yup.string().trim()
                    .matches(/^\d{3}-\d{2}-\d{4}$/, REQUIRED_FIELD)
                    .test('employeeSSN', REQUIRED_FIELD, value => {
                        if (!value) return false;
                        const cleanedSSN = value.replace(/\D/g, '');
                        return cleanedSSN.length === 9;
                    });
            } else if (field.name && (field.name === "firstName" || field.name === "lastName" || field.name === "city")) {
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable();
            } else if (field.name && (field.name === "employeeIdState")) {
                keysValidation[field.name] = Yup.string().trim().matches(/^[A-Za-z\s]*$/, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable();
            } else if (field.name === "identityId") {
                keysValidation[field.name] = Yup.string().trim().matches(/^[A-Za-z0-9\s]*$/, ALPHABET_NUMERIC_ONLY).required(REQUIRED_FIELD).nullable();
            } else if (field.name === "employer") {
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_NUMBER_HYPHEN_DOT_REGEX, ALPHABET_NUMERIC_ONLY).required(REQUIRED_FIELD).nullable();
            } else if (field.name === "employerAddress") {
                keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).nullable();
            } else if (field.name === "indIncomeLastYearOtherIncome") {
                keysValidation[field.name] = Yup.number()
                    .transform((value, originalValue) => {
                        if (typeof originalValue === 'string') {
                            const numberValue = parseFloat(originalValue.replace(/,/g, ''));
                            if (!isNaN(numberValue)) {
                                return numberValue;
                            }
                        }
                        return value;
                    })
                    .typeError('Value must be greater than or equal to 0')
            } else if (field.name === "indOtherIncomeDetail") {
                keysValidation[field.name] = Yup.string().trim().when('indIncomeLastYearOtherIncome', {
                    is: (value, schema) => value && value >= 1,
                    then: Yup.string().trim().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().trim().nullable(),
                })
            } else if (field.name === "spouseIncome") {
                keysValidation[field.name] = Yup.string().trim().when(['marriageStatus', 'usingSpouseIncome'], {
                    is: (marriageStatus: string, usingSpouseIncome: string) => {
                        if((marriageStatus === 'Married') && (usingSpouseIncome === '0')){
                            return true
                        } else if((marriageStatus === 'Married') && (usingSpouseIncome === '1')){
                            return false
                        }
                    },
                    then: Yup.string().trim().required(REQUIRED_FIELD).nullable(),
                    otherwise: Yup.string().trim().nullable(),
                })
            } else if (field.name === "USBasedCompany" || field.name === "workInIndustries") {
                 keysValidation[field.name] = Yup.string().trim().when('currentlyEmployed', {
                     is: (value, schema) => value && (value === 'HOME_MAKER' || value === 'STUDENT' || value === OTHER_VALUE),
                     then: Yup.string().trim().nullable(),
                     otherwise: Yup.string().trim().required(REQUIRED_FIELD).nullable(),
                 })
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD).nullable();
            }
        } else {
            if (field.name && (field.name === "middleName")) {
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).nullable();
            } else if (field.name && (field.name === "age")) {
                keysValidation[field.name] = Yup.number().min(minAge, `Age should be between ${minAge} and 65.`).max(65, `Age should be between ${minAge} and 65.`).typeError(`Age should be between ${minAge} and 65.`);
            } else if (isFromClientDetail && (field.name === "cellPhone" || field.name === "workPhoneNumber")) {
                keysValidation[field.name] = Yup.string().trim();
            } else if (isFromClientDetail && (field.name === "homePhone")) {
                keysValidation[field.name] = Yup.string().trim().nullable(true);
            } else if (field.name && (field.name === "occupationId" || field.name === "industryId")) {
                keysValidation[field.name] = field.type && field.type === "number" ? Yup.number().nullable(true) : Yup.string().trim();
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string().trim();
            }
        }
    });

	[...fieldObj9, ...fieldObj8].forEach((field) => {
		if (field.isRequired) {
			keysValidation[field.name] = Yup.string().trim().when("usCitizen", {
				is: false,
				then: Yup.string().trim().required(REQUIRED_FIELD),
			});
            if (field.name == "howLongInUS"){
                keysValidation[field.name] = Yup.string().trim().when("usCitizen", {
                    is: false,
                    then: Yup.string().trim().required(REQUIRED_FIELD).matches(ALPHABET_WITH_NUMBER_HYPHEN_DOT_REGEX, ALPHABET_NUMERIC_ONLY).required(REQUIRED_FIELD).nullable(),
                });
            } else if (field.name == "countryCitizenship"){
                keysValidation[field.name] = Yup.string().trim().when("usCitizen", {
                    is: false,
                    then: keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable(),
                });
            }
			if (field.options && field.options.length > 0) {
				field.options.forEach((option) => {
					if (option.textField) {
						keysValidation[option.textField.name] = Yup.string().trim().when("usCitizen", {
							is: false,
							then: Yup.string().trim().when(field.name, {
								is: option.value,
                                then: option.textField.name == "greenCardNo" ? Yup.string().trim().required(REQUIRED_FIELD).matches(/^[A-Za-z0-9\s]*$/, ALPHABET_NUMERIC_ONLY) : Yup.string().required(REQUIRED_FIELD),
							}),
						});
					}
				});
			}
		}
	});

    [...fieldObj11].forEach((field) => {
        if (field.name == "otherCitizenship"){
            keysValidation[field.name] = Yup.string().trim().when("usCitizen", {
                is: false,
                then: keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).nullable(),
            });
        }
    });

	fieldObj2.forEach((field) => {
		if (field.isRequired) {
			if (field.name && (field.name === "zipCode")) {
				keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please enter a valid zip code");
			} else if (field.name && (field.name === "state")) {
				keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).nullable().transform((o, v) => {
					if (v && typeof v === "object") {
						return v.key;
					}
					return v || "";
				});
            } else if (field.name === "currentResidenceCountry") {
                keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).nullable();
			}else if (field.isRequiredNullable){
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD).nullable() : Yup.string().trim().required(REQUIRED_FIELD).nullable();
            }
            else if (field.name && field.name === "city" ) {
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable();
            }
            else {
				keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD);
			}

		} else {
			if (field.name && (field.name === "zipCode")) {
				keysValidation[field.name] = Yup.string().trim().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please enter a valid zip code");
			} else if (field.name && (field.name === "state")) {
				keysValidation[field.name] = Yup.string().trim().transform((o, v) => {
					if (v && typeof v === "object") {
						return v.key;
					}
					return v || "";
				});
			} else {
				keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string().trim();
			}
		}
	});

	fieldObj6.forEach((field) => {
		if (field.isRequired) {
			keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD);
		} else {
			if (field.name && (field.name === "contributionAmount") && isFromClientDetail && ageSelected && showClientContribution) {
				let employerContributionValue: any = 0;
				if (client && client.employerContribution && numeral(client.employerContribution).value() > 0 && props.clientDetail && props.clientDetail.role === 6) {
					employerContributionValue = client.employerContribution;
				}
				if (employerContributionValue > 0) {
					keysValidation[field.name] = Yup.number().transform((o, v) => {
						return (v && parseInt(v.replace(/,/g, ""))) || 0;
					}).min(numeral(minVal).value() - numeral(employerContributionValue).value(), `Minimum contribution is ${numeral(minVal).format("$0,0")}`);
				} else {
					keysValidation[field.name] = Yup.number().transform((o, v) => {
						return (v && parseInt(v.replace(/,/g, ""))) || 0;
					}).min(minVal, `Minimum contribution is ${numeral(minVal).format("$0,0")}`);
				}
			} else {
				keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string().trim();
			}
		}
	});

	fieldObj7.forEach((field) => {
		if (field.isRequired) {
			if (field.name && (field.name === "proofExpiryMonth" || field.name === "proofExpiryYear")) {
				if (field.name === "proofExpiryMonth") {
					keysValidation[field.name] = Yup.number().min(1, "Please select expiry month");
				} else if (field.name === "proofExpiryYear") {
					keysValidation[field.name] = Yup.number().min(1, "Please select expiry year");
				}
            } else if (field.name === "employeeIdState"){
                keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD).nullable();
			} else {
				keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD);
			}
		}
	});
	return keysValidation;
}

export default PersonalDetailValidate;